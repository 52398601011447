import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'
import Section from '~components/Section'
import { Logo, MagnifyingGlass } from '~components/Svg'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import SanityLink from '~components/SanityLink'
import { mobile } from '~styles/global'
import { useSiteState } from '~context/siteContext'
import LayoutToggle from '~components/LayoutToggle'
import LayoutToggleMobile from '~components/LayoutToggleMobile'
import { motion, AnimatePresence } from 'framer-motion'
import useScrolledTop from '~utils/useScrolledTop'
import useBreakpoint from '~utils/useBreakpoint'
import Search from '~components/Search'
import { useLocation } from '@reach/router'
import { Global, css } from '@emotion/react'


const Header = ({ className, projectIndex, home }) => {

	const { email, mapLink, address, instagram, companyDescription } = useSiteSettings()
	const top = useScrolledTop()
	const headerRef = useRef(null)
	const [siteState, setSitetState] = useSiteState()
	const { isMobile } = useBreakpoint()
	const [loaded, setLoaded] = useState()
	const [headerExpanded, setHeaderExpanded] = useState(false)
	const [searchOpen, setSearchOpen] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setLoaded(true)
	}, [])

	const handleResizeScroll = () => {
		setSitetState(prevState => ({
			...prevState,
			headerHeight: headerRef?.current?.clientHeight
		}))
	}

	useEffect(() => {
		setSitetState(prevState => ({
			...prevState,
			headerHeight: headerRef?.current?.clientHeight
		}))
		window.addEventListener('resize', handleResizeScroll)
		return () => window.removeEventListener('resize', handleResizeScroll)
	}, [])

	useEffect(() => {
		setSitetState(prevState => ({
			...prevState,
			headerHeight: headerRef?.current?.clientHeight
		}))
		window.addEventListener('scroll', handleResizeScroll)
		return () => window.removeEventListener('scroll', handleResizeScroll)
	}, [])

	useEffect(() => {
		if (top){
			setHeaderExpanded(true)
		} else{
			setHeaderExpanded(false)
		}
	}, [top])

	useEffect(() => {
		setSearchOpen(false)
	}, [location.pathname])

	useEffect(() => {
		if (headerExpanded) {
			setSitetState(prevState => ({
				...prevState,
				headerHeight: headerRef?.current?.clientHeight
			}))
		} else {
			setTimeout(()=> {
				setSitetState(prevState => ({
					...prevState,
					headerHeight: headerRef?.current?.clientHeight
				}))
			}, 320)
		}
	}, [top, headerExpanded])

	const logoLinkToggle = () => {
		if (home){
			navigate('/info')
		} else {
			navigate('/')
		}
	}

	const showGradient = () => {
		if (projectIndex && !isMobile) {
			return true
		} else if ((isMobile && !projectIndex) && (isMobile && !home)) {
			return true
		} else {
			return false
		}
	}

	return(
		<>
			{searchOpen &&
				<Global styles={css`
					body, html {
						overflow: hidden;
					}
				`}/>
			}
			<Wrap className={className} home={home} projectIndex={projectIndex} ref={headerRef}>
				<StyledSection>
					<LogoCol><LogoLink onClick={() => logoLinkToggle()}><StyledLogo top={top} home={home}/></LogoLink></LogoCol>
					<Col>
						<InfoTitle><NavLink className='h4' activeClassName='active' to={'/info'}>Info</NavLink></InfoTitle>
					</Col>
					<Col2>
						<Col2Header>
							<ProjectsTitle><NavLink className='h4' activeClassName='active' to={'/projects'}>Projects</NavLink></ProjectsTitle>
							{loaded &&
									<>
										{	projectIndex &&							
											<>
												<StyledLayoutToggle show={projectIndex}/>
												<div><StyledLayoutToggleMobile show={projectIndex}/></div>
											</>
										}
										<SearchButtonMobile 
											className='h4' 
											onClick={() => setSearchOpen(true)}
										>
											<MagnifyingGlass />
										</SearchButtonMobile>
									</>
							}
						</Col2Header>
						<AnimatePresence>
							{headerExpanded && 
							<DetailsMob  
								className='h4'
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								<Email 
									home={home}
									link={{
										url: `mailto:${email}`
									}}
								>
									{email}
								</Email>
								<Address
									link={{
										url: mapLink,
										blank: true
									}}
									home={home}
								>
									{address}
								</Address>
								<Instagram 
									home={home}
									link={{
										url: `https://www.instagram.com/${instagram}`
									}}
								>
							@{instagram}
								</Instagram>
							</DetailsMob>		
							}
						</AnimatePresence>
					</Col2>
					<Details className='h4'>
						<LinkContainer>
							<Email 
								home={home}
								link={{
									url: `mailto:${email}`
								}}
							>
								{email}
							</Email>
						</LinkContainer>
						<LinkContainer>
							<Address 									
								link={{
									url: mapLink,
									blank: true
								}}
								home={home}>
								{address}
							</Address>
						</LinkContainer>
						<LinkContainer>
							<Instagram 
								home={home}
								link={{
									url: `https://www.instagram.com/${instagram}`,
									blank: true
								}}
							>
						@{instagram}
							</Instagram>
						</LinkContainer>
					</Details>
					<Text className='h4'>
						<RichText content={companyDescription}/>
					</Text>
					<SearchCol>
						<SearchButton 
							className='h4'
							onClick={() => setSearchOpen(true)}
						>
						Search
						</SearchButton>
						<CloseBlock/>
					</SearchCol>
				</StyledSection>
				<AnimatePresence>
					{searchOpen && 
				<Search onClose={() => setSearchOpen(false)}/>
					}
				</AnimatePresence>
				<Gradient 
					show={showGradient()} 
					headerHeight={siteState.headerHeight} 
					home={home}
				/>
			</Wrap>
		</>
	)
}

const Wrap = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
	color: ${props => props.home ? 'var(--white)' : 'var(--black)'};
	transition: color 0.3s;
	transition-delay: 0.5s;
	width: 100%;
`
const Gradient = styled.div`
	opacity: ${props => props.show ? '1' : '0'};
	transition: 0.3s;
	transition-delay: 0.5s;
	background: linear-gradient(180deg, #FFFFFF 65.9%, rgba(255, 255, 255, 0) 100%); 
	height: ${props => `calc(${props.headerHeight}px + 3vw)`};
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: -1;
	pointer-events: none;
	${mobile}{
		height: ${props => `calc(${props.headerHeight}px + 12vw)`};
	}
`
const StyledSection = styled(Section)`
	width: 100%;
	margin-top: 37px;
	${mobile}{
		margin-top: 21px;
	}
`
const LogoCol = styled.div`
	grid-column: span 2;
	display: block;
	${mobile}{
		grid-column: span 3;
	}
`
const LogoLink = styled.button`
	display: block;
`
const StyledLogo = styled(Logo)`
	width: ${props => props.home ? '126px' : '40px'};
	transition: width 0.4s;
	display: block;
	${mobile}{
		width: ${props => props.top ? '19.5vw' : '40px'};
		max-width: 100px;
		transition: width 0.4s;
	}
`
const LinkContainer = styled.div`
	display: inherit;
`
const HeaderTitle = styled.div`
	display: inherit;
	position: relative;
`
const InfoTitle = styled.div`
	${mobile}{
		position: relative;
    top: -0.85vw;
	}
`
const ProjectsTitle = styled(HeaderTitle)`
`
const NavLink = styled(Link)`
	position: relative;
	display: block;
	:before{
		content: '';
		position: absolute;
		top: -5px;
		bottom: -5px;
		right: -5px;
		left: -5px;
	}
	:after{
		content: '';
		background-color: transparent;
		height: 1px;
		position: absolute;
		left: 0;
		right: 0;
		top: calc(100% - 0.04em);
		transition: background-color 0.3s;
		transition-delay: 0.5s;
	}
	&.active{
		:after{
			background-color: var(--black)
		}
	}
`
const Col = styled.div`
	grid-column: span 2;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	${mobile}{
		grid-column: span 3;
	}
`
const Col2 = styled(Col)`
	${mobile}{
		grid-column: span 6;
		justify-content: space-between;
	}
`
const Col2Header = styled.div`
	display: flex;
  flex-direction: column;
	justify-content: space-between;
	height: 100%;
	${mobile}{
		margin-bottom: 14px;
		width: 100%;
		height: auto;
		flex-direction: row;
		position: relative;
  	top: -0.85vw;
	}
`
const StyledLayoutToggle = styled(LayoutToggle)`
	opacity: ${props => props.show ? '1' : '0'};
	pointer-events; ${props => props.show ? 'all' : 'none'};
	transition: ${props => props.show ? 'opacity 0.25s' : '0.15s'};
	transition-delay: ${props => props.show ? '0.5s' : '0s'};
	margin-top: 0.3em;
	${mobile}{
		display: none;
	}
`
const StyledLayoutToggleMobile = styled(LayoutToggleMobile)`
	display: none;
	${mobile}{
		opacity: ${props => props.show ? '1' : '0'};
		pointer-events: ${props => props.show ? 'all' : 'none'};
		transition: opacity 0.25s;
		transition-delay: 0.5s;
		display: inline-flex;
		position: fixed;
		top: 21px;
		right: 15vw;
	}
`
const Details = styled.div`
	grid-column: span 2;
	${mobile}{
		display: none;
	}
`
const DetailsMob = styled(motion.div)`
	display: none;
	${mobile}{
		display: block;
	}
`
const UnderlineLink = styled(SanityLink)`
	position: relative;
	:after{
		content: '';
		background-color: transparent;
		height: 1px;
		position: absolute;
		left: 0;
		right: 0;
		top: calc(100% - 0.04em);
		transition: background-color 0.25s;
	}
	&:hover{
		:after{
			background-color: ${props => props.home ? 'var(--white)' : 'var(--black)'};
		}
	}
`
const Email = styled(UnderlineLink)`
`
const Address = styled(UnderlineLink)`
	display: inline-block;
`
const Instagram = styled(UnderlineLink)`
`
const Text = styled.div`
	grid-column: span 3;
	${mobile}{
		display: none;
	}
`
const SearchCol = styled.div`
	grid-column: span 1;
	display: flex;
	align-items: start;
	justify-content: flex-end;
	${mobile}{
		display: none;
	}
`
const SearchButton = styled.button`
	display: block;
`
const CloseBlock = styled.div`
	width: 0.45rem;
	margin-left: 2vw;
`
const SearchButtonMobile = styled.button`
	display: none;
	${mobile}{
		display: block;
		margin-left: 10vw;
		position: fixed;
		top: 20px;
		right: 4vw;
		>svg{
			width: 0.8rem;
		}
	}
`
Header.propTypes = {
	className: PropTypes.string,
	transparent: PropTypes.bool,
	projectIndex: PropTypes.bool,
	home: PropTypes.bool,
}

export default Header
